/* About.css */

#about {
    text-align: center;
    padding: 20px;
}

#about h2 {
    color: #fff;
    margin-bottom: 20px;
}

#about p {
    color: #fff;
    font-size: 18px;
    line-height: 1.6;
    animation: textAppearDisappear 10s infinite;
}

@keyframes textAppearDisappear {
    0% { opacity: 0; }
    10% { opacity: 1; }
    90% { opacity: 1; }
    100% { opacity: 0; }
}

.dd-form-container {
    margin-top: 40px;
    text-align: center;
    background-color: #1b1b1b;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
}

.dd-form-container h3 {
    color: #fff;
    margin-bottom: 20px;
    font-family: 'Courier New', Courier, monospace; /* Adds a professional, typewriter-like font */
}

.dd-form-image {
    position: relative;
    cursor: pointer;
}

.dd-form-image img {
    width: 100%; /* Make it responsive */
    max-width: 267px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    filter: blur(5px); /* Initially blurred */
    transition: filter 0.5s, transform 0.5s;
}

.dd-form-image:hover img {
    filter: blur(0);
    transform: scale(1.05); /* Zoom in slightly */
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
}

.modal-content {
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 90%;
    max-height: 90%;
}

.modal-content img {
    max-width: 100%;
    max-height: 100%;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 20px;
    color: #000;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
}

.images-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Ensure images wrap on smaller screens */
    gap: 20px;
    margin-top: 40px;
}

.image-wrapper {
    position: relative;
    width: 150px; /* Adjust size as needed */
    height: 150px; /* Adjust size as needed */
    overflow: hidden;
    cursor: pointer;
}

.image-wrapper.circular img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: toss 5s infinite;
}

@keyframes toss {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}

.horizontal-image-wrapper {
    width: 300px; /* Adjust size as needed */
    max-width: 100%;
}

.horizontal-image {
    width: 100%;
    border-radius: 10px;
    filter: blur(5px);
    transition: filter 0.5s, transform 0.5s;
}

.horizontal-image-wrapper:hover .horizontal-image {
    filter: blur(0);
    transform: scale(1.05);
}

/* Responsive Design */
@media (max-width: 768px) {
    #about p {
        font-size: 16px;
    }

    .dd-form-image img {
        max-width: 100%;
    }

    .image-wrapper {
        width: 100px;
        height: 100px;
    }

    .horizontal-image-wrapper {
        width: 200px;
    }
}
