/* Footer.css */

footer {
  text-align: center;
  padding: 20px;
  background-color: black;
  color: white;
  font-family:'Courier New', Courier, monospace, sans-serif;
  border-top: 1px solid black;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.5);
}

footer p {
  margin: 10px 0;
  font-size: 18px;
  color: #d3d3d3;
}

footer p:first-child {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 5px;
  color: #ffffff;
}

footer p:nth-child(2) {
  font-size: 16px;
  color: #d3d3d3;
}

/* Animation for animated-image2 */
.animated-image2 {
  width: 200px; /* Adjust the width as needed */
  height: auto;
  animation: rotateAndBounce 5s ease-in-out infinite;
  margin-bottom: 20px; /* Add some space below the image */
}

@keyframes rotateAndBounce {
  0%, 100% {
      transform: translateY(0) rotate(0deg);
  }
  50% {
      transform: translateY(-5px) rotate(20deg);
  }
}

@media (max-width: 768px) {
  .animated-image2 {
      width: 300px; /* Adjust the width for smaller screens */
  }
}
