/* HowToBuy.css */

#how-to-buy {
    text-align: center;
    padding: 20px;
    background-color: rgb(188, 4, 4);
    color: white;
    font-family: 'Arial', sans-serif;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    max-width: 800px;
    margin: 0 auto;
  }
  
  #how-to-buy h2 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .step-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .step {
    background-color: #000000;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .step h3 {
    font-size: 28px;
    margin-bottom: 10px;
    color: #43E4FF;
  }
  
  .step p {
    font-size: 18px;
    line-height: 1.6;
    color: #d3d3d3;
  }
  
  .socials {
    margin-top: 40px;
    text-align: center;
  }
  
  .socials p {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .icons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .icons a {
    color: white;
    font-size: 30px;
    transition: transform 0.3s, color 0.3s;
  }

   .icons a:hover {
    transform: scale(1.2);
    color: #43E4FF;
  }