/* Animation.css */

#animation {
  text-align: center; /* Center align for better mobile view */
  padding: 20px;
}

#animation h2 {
  color: #fff;
  margin-bottom: 20px;
}

.video-container {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px; /* Space between elements */
}

#video-container #background-video {
  width: 35px; /* Set the video to a small size */
  height: 50px;
  border-radius: 50%; /* Make it round if needed */
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.5); /* Red glow effect */
  object-fit: cover;
  background: transparent; /* Ensure background is transparent */
}



.animated-image {
  width: 50%; /* Adjust the percentage based on your layout needs */
  max-width: 400px; /* Max width for large screens */
  height: auto;
  animation: pointToLink 4s ease-in-out infinite;
  margin-bottom: 20px; /* Space below the image */
}

@keyframes pointToLink {
  0%, 100% { transform: translateX(0); }
  50% { transform: translateX(10px); }
}

@media (max-width: 768px) {
  .animated-image {
      width: 80%; /* Adjust for smaller screens */
  }

  #video-container #background-video {
      width: 90%; /* Adjust for smaller screens */
  }
}
