header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    background-color: #1b1b1b;
    color: #fff;
    width: 100%;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    color: black;
    height: 120px; /* Adjust height as needed */
    background: transparent; /* Ensure the background is transparent */
}

.coin-animation {
    flex: 1; /* Allow the video to take up space */
    display: flex;
    justify-content: flex-start; /* Align the video to the start (left) */
    align-items: center;
    background: transparent; /* Ensure the background is transparent */
}

.coin-animation video {
    width: 100%;
    max-width: 270px;
    height: auto;
    background: transparent; /* Ensure the video background is transparent */
}

.ticker-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}

.ticker-text {
    font-size: 1.5em;
    color: #43E4FF;
    font-weight: bold;
}

.social-icons {
    flex: 1; /* Allow the icons to take up space */
    display: flex;
    justify-content: flex-end; /* Align the icons to the end (right) */
    gap: 20px;
    align-items: center;
    margin-top: 10px;
}

.social-icons a {
    color: #d1d1d3;
    margin: 0 10px;
    transition: transform 0.3s, color 0.3s;
}

.social-icons a:hover {
    transform: scale(1.2);
    color: #43E4FF;
}

.dexscreener-icon {
    width: 50px;
    height: 50px;
    transition: transform 0.3s;
}

.social-icons a:hover .dexscreener-icon {
    transform: scale(1.2);
}

.header-heading {
    margin-top: 20px;
    font-size: 2em;
    font-weight: bold;
    color: #fff;
    text-align: center;
    animation: slide-in 3s ease-in-out infinite alternate;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
}

@keyframes slide-in {
    0% {
        transform: translateX(-50%) translateY(0);
    }
    100% {
        transform: translateX(-50%) translateY(20px);
    }
}

/* Media Queries for Responsive Design */
@media (min-width: 768px) {
    header {
        flex-direction: row;
        justify-content: space-between;
    }

    .header-container {
        flex-direction: row;
    }

    .coin-animation {
        margin-right: auto;
    }

    .ticker-container {
        margin: 0 20px;
    }

    .social-icons {
        margin-left: auto;
    }

    .header-heading {
        font-size: 2.5em;
    }
}
