/* App.css */
body {
  background-color: rgb(188, 4, 4);
  color: white;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

header {
  background-color: black;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.coin-animation {
  width: 100px;
  height: 100px;
}

.social-icons img {
  margin-left: 10px;
  width: 30px;
  height: 30px;
}

.moving-bar {
  background-color: rgb(88, 87, 86);
  padding: 10px 0;
  text-align: center;
  font-size: 24px;
  animation: move 10s linear infinite;
  width: 100%;
  box-sizing: border-box;
}

@keyframes move {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

/* Specific style for the first moving bar */
.first-bar {
  font-size: 15px; /* Double size */
  font-weight: bold; /* Bold */
  background: linear-gradient(90deg, #f42a0b4d, #D839E3, #f2421e);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: breaking 0.99s infinite, move 10s linear infinite;
}

@keyframes breaking {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

/* Specific style for the second moving bar */
.second-bar {
  font-size: 18px; /* Double size */
  font-weight: bold; /* Bold */
  background: linear-gradient(90deg, #5d0707, rgb(236, 89, 89), #d60404);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: complexBreaking 3s infinite, move 10s linear infinite;
}

@keyframes complexBreaking {
  0% { opacity: 1; transform: scale(1) rotate(0deg); letter-spacing: normal; }
  25% { opacity: 0.5; transform: scale(1.1) rotate(-5deg); letter-spacing: 20px; }
  50% { opacity: 0; transform: scale(1) rotate(0deg); letter-spacing: normal; }
  75% { opacity: 0.5; transform: scale(0.9) rotate(5deg); letter-spacing: 20px; }
  100% { opacity: 1; transform: scale(1) rotate(0deg); letter-spacing: normal; }
}

section {
  padding: 20px;
  text-align: center;
}

footer {
  background-color: black;
  padding: 10px;
  text-align: center;
}

/* New styles for the layered effect */
.page-container {
  margin: 20px auto;
  padding: 20px;
  background-color: black; 
  border: 1px solid #444;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  max-width: 1400px; /* Adjust as needed */
  border-radius: 10px;
}

/* Ticker animation */
.ticker-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.ticker-text {
  font-size: 25px; /* Increased font size */
  font-weight: bold; /* Added bold */
  background: linear-gradient(90deg, #fc052a, #D839E3, #c63c00e0);
  background-clip: text; /* Standard property */
  -webkit-background-clip: text; /* WebKit specific */
  -webkit-text-fill-color: transparent;
  animation: tickerAnimation 5s linear infinite;
}

@keyframes tickerAnimation {
  0% { transform: scale(1) rotate(0); }
  25% { transform: scale(1.2) rotate(5deg); }
  50% { transform: scale(1) rotate(0); }
  75% { transform: scale(1.2) rotate(-5deg); }
  100% { transform: scale(1) rotate(0); }
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .moving-bar {
    font-size: 20px;
    padding: 8px 0;
  }

  .first-bar, .second-bar {
    font-size: 24px;
  }

  .ticker-text {
    font-size: 20px;
  }

  .page-container {
    padding: 15px;
  }

  header {
    flex-direction: column;
  }

  .header-container {
    flex-direction: column;
    align-items: center;
  }

  .social-icons {
    margin-top: 10px;
  }
}
